import { combineReducers } from 'redux';

import exception from 'react-redux-app/lib/modules/exception/reducer';
import globalError from 'react-redux-app/lib/modules/globalError/reducer';
import grid from 'react-redux-app/lib/modules/grid/reducer';
import i18n from 'react-redux-app/lib/modules/i18n/reducer';
import modals from 'react-redux-app/lib/modules/modal/reducer';

import adultWarning from './adultWarning';
import isRootMounted from './isRootMounted';

import favoriteMark from '../../core/favoriteMark/reducer';
import fileUploadProgress from '../../core/fileUploadProgress/reducer';
import gridColsSizeControl from '../../core/gridColsSizeControl/reducer';
import sequentialMediaUploader from '../../core/sequentialMediaUploader/reducer';
import pushNotification from '../../core/pushNotification/reducer';
import ticket from '../../core/ticket/reducer';
import videoPreview from '../../core/videoPreview/reducer';

import account from '../../account/reducer';
import affiliate from '../../affiliate/reducer';
import agency from '../../agency/reducer';
import appointment from '../../appointment/reducer';
import auth from '../../auth/reducer';
import award from '../../award/reducer';
import balance from '../../balance/reducer';
import bannedUser from '../../bannedUser/reducer';
import blackList from '../../blackList/reducer';
import blog from '../../blog/reducer';
import chatGallery from '../../chatGallery/reducer';
import deposit from '../../deposit/reducer';
import dialog from '../../dialog/reducer';
import dialogMessage from '../../dialogMessage/reducer';
import emoticonShop from '../../emoticonShop/reducer';
import eventAward from '../../eventAward/reducer';
import favorite from '../../favorite/reducer';
import feedback from '../../feedback/reducer';
import gift from '../../gift/reducer';
import initSettings from '../../initSettings/reducer';
import interview from '../../interview/reducer';
import landingPage from '../../landingPage/reducer';
import latestItem from '../../latestItem/reducer';
import legal from '../../legal/reducer';
import mediaViewer from '../../mediaViewer/reducer';
import model from '../../model/reducer';
import newsFeed from '../../newsFeed/reducer';
import ownPost from '../../ownPost/reducer';
import ownSaleItem from '../../ownSaleItem/reducer';
import partner from '../../partner/reducer';
import password from '../../password/reducer';
import prize from '../../prize/reducer';
import profile from '../../profile/reducer';
import purchase from '../../purchase/reducer';
import registration from '../../registration/reducer';
import revenue from '../../revenue/reducer';
import saleItem from '../../saleItem/reducer';
import sitemap from '../../sitemap/reducer';
import siteNews from '../../siteNews/reducer';
import underwear from '../../underwear/reducer';
import user from '../../user/reducer';
import visitor from '../../visitor/reducer';


export default combineReducers({
  // vendor
  exception,
  globalError,
  grid,
  i18n,
  modals,

  adultWarning,
  isRootMounted,

  favoriteMark,
  fileUploadProgress,
  gridColsSizeControl,
  sequentialMediaUploader,
  pushNotification,
  ticket,
  videoPreview,

  account,
  affiliate,
  agency,
  appointment,
  auth,
  award,
  balance,
  bannedUser,
  blackList,
  blog,
  chatGallery,
  deposit,
  dialog,
  dialogMessage,
  emoticonShop,
  eventAward,
  favorite,
  feedback,
  gift,
  initSettings,
  interview,
  landingPage,
  latestItem,
  legal,
  mediaViewer,
  model,
  newsFeed,
  ownPost,
  ownSaleItem,
  partner,
  password,
  prize,
  profile,
  purchase,
  registration,
  revenue,
  saleItem,
  sitemap,
  siteNews,
  underwear,
  user,
  visitor,
});
