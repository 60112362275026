import { MODAL_DIALOG__GET_CREDITS } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowGetCreditsDialog = (
  error = null,
  welcomeBonus = false,
  cryptoBonus = false,
  addNewCard = false,
  modelId = null,
  modelNick = null
) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__GET_CREDITS, {
    error,
    welcomeBonus,
    cryptoBonus,
    addNewCard,
    modelId,
    modelNick,
  }))
);

export const actionHideGetCreditsDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__GET_CREDITS))
);
