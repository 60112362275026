import { getUri } from 'react-redux-app/lib/modules/routing/utils';
import { stripLangFromUri } from '../../core/i18n/utils';
import { getUrl } from '../../app/utils/url';
import { hasProfileHash } from '../../profile/utils';

import {
  ACTION_VISIT_PAGE__REQUEST,
  ACTION_VISIT_PAGE__SUCCESS,
  ACTION_VISIT_PAGE__FAIL,
} from '../actionTypes';

import { PATH_PROFILE, PATH_DIALOG } from '../../app/constants/url';
import { FIRST_VISIT_COOKIE, COOKIE_FIRST_VISIT_EXPIRES } from '../constants';

import { getCurrentLangForUrl } from '../../core/i18n/selectors';
import { getFrontendToSeoPathMap } from '../../initSettings/selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionSetAuthData } from './auth';


const actionVisitPageSuccess = () => ({
  type: ACTION_VISIT_PAGE__SUCCESS,
});

const actionVisitPageFail = error => ({
  type: ACTION_VISIT_PAGE__FAIL,
  payload: { error },
});

export const actionVisitPageRequest = (locationArg = null) => (
  (dispatch, getState, {
    apiClientHelper,
    getCookie,
    setCookie,
    history,
  }) => {
    dispatch({
      type: ACTION_VISIT_PAGE__REQUEST,
    });

    const location = locationArg || history.location;

    const firstVisit = getCookie(FIRST_VISIT_COOKIE);
    const langForUrl = getCurrentLangForUrl(getState());

    const profilePath = `/${PATH_PROFILE}`;
    const dialogPath = `/${PATH_DIALOG}`;
    let pathnameWithoutLang = stripLangFromUri(location.pathname);

    if (pathnameWithoutLang.startsWith(profilePath) || hasProfileHash(location.hash)) {
      pathnameWithoutLang = profilePath;
    } else if (pathnameWithoutLang.startsWith(dialogPath)) {
      pathnameWithoutLang = dialogPath;
    }

    pathnameWithoutLang = (
      getFrontendToSeoPathMap(getState())[pathnameWithoutLang] || pathnameWithoutLang
    );

    const requestUriWithoutLang = getUri({ ...location, hash: '' })
      .replace(location.pathname, pathnameWithoutLang);

    return apiClientHelper.post(
      'event/page_visit',
      {
        data: {
          isFirstVisit: !firstVisit,
          requestUriWithoutLang,
          requestUriWithLang: getUrl(langForUrl, requestUriWithoutLang),
        },
        retry: true,
      }
    ).then(
      auth => {
        if (!firstVisit) {
          setCookie(
            FIRST_VISIT_COOKIE,
            Date.now(),
            {
              expires: COOKIE_FIRST_VISIT_EXPIRES,
              path: '/',
            }
          );
        }

        dispatch(actionSetAuthData(auth));

        dispatch(actionVisitPageSuccess());
      },
      error => {
        dispatch(actionVisitPageFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
