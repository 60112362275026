import { getIsAuthenticated } from '../../auth/selectors';

import { actionShowLoginDialog } from '../../auth/actions/dialogs/loginDialog';
import { actionClearGateResult } from './buyCreditsLocally';
import { actionSetIsWhiteModeActivated } from './setIsWhiteModeActivated';
import { actionShowGetCreditsDialog } from './dialogs/getCreditsDialog';


export const actionGetCredits = (
  error, welcomeBonus, cryptoBonus, addNewCard, modelId
) => (
  (dispatch, getState) => {
    if (getIsAuthenticated(getState())) {
      dispatch(actionClearGateResult());
      dispatch(actionSetIsWhiteModeActivated(false));
      dispatch(actionShowGetCreditsDialog(
        error, welcomeBonus, cryptoBonus, addNewCard, modelId
      ));
    } else {
      dispatch(actionShowLoginDialog());
    }
  }
);
