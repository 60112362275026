import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles/index.css.json';


const propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const Overlay = ({ id = null, children, className = null }) => {
  const toggleProfile = isAdd => {
    const { classList } = document.getElementsByTagName('html')[0];
    if (isAdd) {
      classList.add(styles.html_withOverlay); // eslint-disable-line camelcase
    } else {
      classList.remove(styles.html_withOverlay); // eslint-disable-line camelcase
    }
  };

  useEffect(() => {
    toggleProfile(true);

    return () => { toggleProfile(false); };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id={id} className={classnames(styles.overlay, className)}>
      {children}
    </div>
  );
};

Overlay.propTypes = propTypes;

export default Overlay;
